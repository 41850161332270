<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row class="vd_posi">
        <el-col :md="24">
          <el-upload
            :disabled="!btn.add"
            ref="upload"
            class="upload-demo"
            :action="uploadUrl"
            :data="uploadData"
            :on-preview="handlePreview"
            multiple
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
          >
            <el-button class="vd_mrt" slot="trigger" size="small" type="primary" :disabled="!btn.add">上传</el-button>
            <div slot="tip" class="el-upload__tip">单个文件大小不得超过50MB</div>
          </el-upload>
          <div class="vd_posi_o">
            <el-button size="small" type="success" @click="downClick" :disabled="!btn.add">批量下载</el-button>
            <el-button size="small" type="danger" @click="manyDeleteClick" :disabled="!btn.add" v-if="delFlag">
              批量删除
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-table ref="docuList" :data="tableData" border @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" width="60" align="center">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="文件名" prop="docu_name" />
          <el-table-column label="格式" prop="docu_suffix" />
          <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip />
          <el-table-column label="操作" align="center" width="120">
            <template v-slot="scope">
              <el-row type="flex" justify="space-around">
                <el-col :md="8">
                  <el-link type="success" class="vg_cursor" @click="download(scope.row)">下载</el-link>
                </el-col>
                <el-col :md="8" v-if="delFlag">
                  <el-link type="danger" class="vg_cursor" @click="remove(scope.$index, scope.row)"
                           :disabled="!btn.add">删除
                  </el-link>
                </el-col>
                <el-col :md="8">
                  <el-link type="warning" class="vg_cursor" @click="seeShow(scope.row)"
                           v-if="imgarr.indexOf(scope.row.docu_suffix) !== -1"
                  >查看
                  </el-link>
                  <el-link @click="openPDF(scope.row.docu_url)" class="vg_ml_8 vg_pointer"
                           v-if="'pdf' === scope.row.docu_suffix"
                  >查看
                  </el-link>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="50%" class="showImg">
      <el-image class="showImg" :src="showImgUrl" fit="contain"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { docuAPI } from '@api/modules/docu';
import helper from '@assets/js/helper';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export default {
  name: 'docuAbout',
  props: {
    docuAbout: Object,
    delFlag: {
      type: Boolean,
      required: false,
      default: true
    }
    // isShow:{
    //   type:Boolean,
    //   required:true,
    // },
  },
  data() {
    return {
      uploadData: {
        perm_id: '',
        form_id: ''
      },
      tableData: [],
      // fileList:[],
      uploadUrl: helper.modePrepend(docuAPI.uploadDocuments),
      docuUrl: '',
      docuName: '',
      btn: {},
      dialogVisible: false,
      showImgUrl: '',
      beforeFlag: 0,
      successFlag: 0,
      multipleSelection: [],
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async openPDF(url) {
      try {
        const headers = new Headers({ 'Content-Disposition': 'attachment' });
        const response = await fetch(url, { method: 'GET', headers });
        const blob = await response.blob();
        window.open(URL.createObjectURL(new File([blob], 'view.pdf', { type: 'application/pdf' })));
      } catch (error) {
        console.error('文件下载失败:', error);
      }
    },
    initData() {
      this.getDocus();
    },
    // buttonClick(){
    //   if(this.isShow){
    //     this.$confirm('请确定是否反生效?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     })
    //     this.beforeUpload()
    //   }else{
    //     this.$message({
    //       type:'warning',
    //       message:'单据信息编辑未完成'
    //     })

    //   }
    //   console.log('5555',this.isShow)
    // },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    //上传
    handlePreview(file) {
    },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    // handleExceed(){
    //   this.$message.error('只能上传一个文件')
    // },
    beforeUpload(file) {
      let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
      if (fileName.length > 100) {
        this.$message.warning('文件名字过长! 长度不可大于100!');
        return false;
      }
      if (file.name === ' ' || file.name === null) {
        this.$message.warning('文件名字不能为空');
        return false;
      }
      if (file.size === 0) {
        this.$message.warning('不能上传空文件');
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 50;
      this.uploadData.perm_id = this.$route.query.perm_id;
      this.uploadData.form_id = this.$route.query.form_id;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 50MB!');
      }
      this.beforeFlag++;
      return isLt5M;
    },
    handleSuccess(val) {
      if (val.code === 2) {
        this.$message({
          type: 'error',
          message: '上传文件不能为空文件！'
        });
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
      // setTimeout(()=>{
      //   this.$refs.upload.clearFiles(); //去掉文件列表
      // },400)
      this.initData();
    },
    //获取相关文档列表
    getDocus() {
      get(docuAPI.getDocus, { perm_id: this.$route.query.perm_id, form_id: this.$route.query.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    manyDeleteClick() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = [];
          for (let i = 0; i < this.multipleSelection.length; i++) {
            temp.push(this.multipleSelection[i].docu_pter_id);
          }
          let ids = temp.join(',');
          post(docuAPI.destroyDocuPtersByIds, { docu_pter_ids: ids })
            .then(res => {
              if (res.data.code === 0) {
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {
        });
    },
    downClick() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      let companyAttachmentsList = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let docuUrl = this.helper.megPath(this.multipleSelection[i].docu_url);
        companyAttachmentsList.push(docuUrl);
      }
      //companyAttachmentsList是我自己的附件url地址数组 你可以改成你的数组
      companyAttachmentsList.forEach((item, index) => {
        let { docu_name, docu_suffix } = this.multipleSelection[index];
        const promise = this.helper.downloadZip(item).then(data => {
          // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split('/');
          const file_name = docu_name + '.' + docu_suffix; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise); //加到promises队列里
      });
      Promise.all(promises).then(() => {
        //异步队列全部完成时 执行下面代码
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流
          saveAs(content, '下载文件包.zip'); // 利用file-saver保存文件
        });
      });
    },
    //下载
    download(row) {
      this.docuUrl = this.helper.megPath(row.docu_url);
      this.docuName = row.docu_name + '.' + row.docu_suffix;
      this.helper.downloadItem(this.docuUrl, this.docuName);
    },
    //单行删除
    remove(index, row) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(docuAPI.destroyDocuPterById, { docu_pter_id: row.docu_pter_id })
            .then(res => {
              if (res.data.code === 0) {
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {
        });
    },
    seeShow(row) {
      this.dialogVisible = true;
      this.showImgUrl = helper.megPath(row.docu_url);
    }
  }
};
</script>

<style scoped lang="scss">
.upload-demo ::v-deep .el-upload--text {
  width: auto;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
}

.el-upload__tip {
  margin-bottom: 16px;
}

::v-deep .el-dialog__body {
  text-align: center;
}

.vd_dis {
  display: flex;
}

.vd_posi {
  position: relative;
}

.vd_posi_o {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
